<template>
  <div class="hello">
<div class="holder">
<ul>
<li v-for="(data, index) in skills" :key='index'>{{ index }}. {{ data.skill }}</li>
</ul>

<div v-bind:class="{ alert:showAlert }"></div>
</div>
  
  </div>
</template>

<script>
export default {
  metaInfo: {
      title: 'Wuhu nice VUE Skills',
      titleTemplate: '%s - Yay!',
      htmlAttrs: {
        lang: 'en',
        amp: true
      }
    },

    

  name: 'Skills',
  data() {
    return {
      skills: [
        {"skill": "REACT"},
        {"skill": "CSS"},
       ],

       showAlert: true


      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

body {
  background-color: #f8f8f8;
}


.alert {
  background-color: yellow;
  width: 100%;
  height: 100px;
}
</style>
