<template>
  
<div class="about">

<h1>Foside denne her</h1>

<p>
Dette er vores fo-fo-fo-fo-side.
  </p>
</div>
</template>
<script>
export default {
  metaInfo: {
      title: 'Forside',
      titleTemplate: '%s - Yay!',
      htmlAttrs: {
        lang: 'en',
        amp: true
      }
    },

  name: 'Home',
  data() {
    return {
      their_name: this.$route.params.name

      }
    }
  }
</script>