import Vue from 'vue'
import Router from 'vue-router'
import Home from './components/Home.vue'
import Skills from './components/Skills.vue'
import About from './components/About.vue'
import Seo from './components/Seo.vue'
import Ads from './components/Ads.vue'
import Programmering from './components/Programmering.vue'

Vue.use(Router)


export default new Router ({
	mode: 'history',
	base: process.env.BASE_URL,

routes: [
{

	path: '/skills',
	name: 'skills',
	component: Skills

},

{

	path: '/',
	name: 'home',
	component: Home

},

{
	path: '/om',
	name: 'om',
	component: About
	},

	{
	path: '/seo',
	name: 'seo',
	component: Seo

	},

{
	path: '/ads',
	name: 'ads',
	component: Ads
	},


{
	path: '/programmering',
	name: 'programmering',
	component: Programmering
	},


	]



})