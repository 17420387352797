import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Meta from 'vue-meta';

Vue.use(Meta);


Vue.config.productionTip = false


new Vue({
  router,
  render: h => h(App),
  // RenderAfterDocument Event:'render-event'name must correspond to vue-config.js
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')